$tinyPadding: var(--tinyPadding);

.CommentCount {
    font-weight: 600;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: rgb(var(--lighterTextColor));

    :global(.Icon.comment-icon) {
        width: 1em;
        margin-right: $tinyPadding;
        vertical-align: middle;
    }

    .count-text {
        font-size: 0.85em;
    }

    :global(.theme-br) & {
        display: none;
    }
}
