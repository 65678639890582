@use 'mixins';

.share-block {
    --cmp-share-block-background-color: var(--lightSectionBack);
    --cmp-share-block-border-radius: 0;
    --cmp-share-block-color: var(--lighterTextColor);
    --cmp-share-block-hover-color: var(--textColor);

    :global(.theme-vi) &,
    :global(.theme-br) & {
        --cmp-share-block-border-radius: var(--circleRadius);
    }

    :global(.theme-bi) &,
    :global(.theme-rw) & {
        --cmp-share-block-background-color: 0, 0, 0;
        --cmp-share-block-color: 255, 255, 255;
        --cmp-share-block-hover-color: 255, 255, 255;
    }

    :global(.theme-mh) &,
    :global(.theme-wh) & {
        --cmp-share-block-background-color: var(--mainColor);
        --cmp-share-block-color: var(--onMainColor);
        --cmp-share-block-hover-color: 255, 255, 255;
    }
}

.share-block {
    display: flex;
    align-items: center;
}

.share-block--extended {
    display: block;
    font-size: 1em;
}

.list {
    display: flex;
    align-items: center;
    gap: var(--tinyPadding);
}

.list--premium {
    .link {
        background: rgb(var(--secondaryTextColor));
    }

    path {
        fill: rgb(var(--lighterTextColor));
    }
}

.list-item {
    display: block;
}

.list-item--link {
    position: relative;
}

.link {
    display: flex;
    width: 2em;
    height: 2em;
    color: rgb(var(--cmp-share-block-color));
    background: rgb(var(--cmp-share-block-background-color));
    border-radius: var(--cmp-share-block-border-radius);
    align-items: center;
    justify-content: center;
    text-decoration: none;

    :global(.Logo) {
        display: inline-block;
        vertical-align: middle;
        width: 1em;
        height: 1.25em;

        &:global(.twitter-logo),
        &:global(.email-logo) {
            width: 1em;
            height: 1em;
        }
    }

    path {
        fill: rgb(var(--cmp-share-block-color));
    }

    &:hover {
        background: rgb(var(--cmp-share-block-background-color), 0.75);

        svg,
        path {
            pointer-events: none;
            fill: rgb(var(--cmp-share-block-hover-color));
            color: rgb(var(--cmp-share-block-hover-color));
        }
    }
}

.copied-message {
    @extend %tag-small;

    display: block;
    position: absolute;
    width: auto;
    padding: var(--tinyPadding);
    left: -125%;
    top: -2.5em;
    border-radius: calc(2 * var(--standardRadius));
    line-height: 1em;
    color: rgb(var(--invertedTextColor));
    background: rgb(var(--invertedBackground));
    text-wrap: nowrap;
    opacity: 0.8;
}
