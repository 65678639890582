@use 'mixins';

.NewsBody {
    margin: var(--standardPadding);

    > ol,
    > ul {
        margin-top: var(--standardPadding);
        line-height: 1.4em;
        list-style: initial;
        padding-left: var(--hugePadding);
    }

    ol {
        list-style: decimal;
    }

    > h1 {
        @extend %article-heading-large;
    }

    > h2 {
        @extend %article-heading-medium;

        margin-top: var(--largePadding);

        .theme-vn &,
        .theme-vp & {
            line-height: 2em;
        }
    }

    > h3 {
        .theme-vp &,
        .theme-vn & {
            line-height: 1.625em;
        }
    }

    > h3,
    > h4 {
        @extend %article-heading-small;
        margin-top: var(--largePadding);
    }

    > p {
        @extend %paragraph-medium;
    }

    > p,
    > strong p {
        @extend %paragraph-medium;
        margin: var(--standardPadding) 0 0;
    }

    > strong p {
        @extend %paragraph-large;
    }

    > img,
    > p img {
        max-width: 100%;
        height: auto;
    }

    :global(.ad) {
        margin-left: calc(var(--standardPadding) * -1);
        margin-right: calc(var(--standardPadding) * -1);
    }

    @include mixins.responsive(m, below) {
        > p,
        > strong,
        > strong p {
            font-size: 1em !important;
        }

        > img,
        > p img {
            margin: calc(var(--standardPadding) * -1);
            width: calc(100% + (2 * var(--standardPadding)));
            max-width: none;
        }
    }
}
