@use 'mixins';

.AboutWriter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgb(var(--darkestBackground));
    color: rgb(var(--invertedTextColor));
    text-align: center;
    position: relative;
    margin-top: calc(var(--hugePadding) + var(--hugePadding));
    border-radius: 2px;

    img {
        position: absolute;
        top: calc(-1 * (var(--largePadding) + var(--hugePadding)));
        margin: auto;
        left: 0;
        right: 0;
    }

    .author-meta {
        padding-top: calc(var(--hugePadding) + var(--standardPadding));
    }

    .author-fullname {
        @extend %premium-heading-large;

        font-size: 2em;
        color: rgb(var(--invertedTextColor));
        margin-top: var(--standardPadding);
        margin-bottom: var(--smallPadding);
    }

    .author-function {
        @extend %tag-medium;

        padding: 0px var(--standardPadding);
        background-color: rgb(var(--background));
    }

    .author-bio {
        margin-top: var(--largePadding);
        padding: 0 calc(var(--hugePadding) + var(--hugePadding));
        line-height: var(--text-line-height);
    }

    .author-button {
        margin-top: var(--standardPadding);
        margin-bottom: calc(var(--largePadding) + var(--smallPadding));

        span {
            margin-left: var(--tinyPadding);
        }
    }

    @include mixins.responsive(m, below) {
        .author-bio {
            padding: 0 calc(var(--largePadding));
        }
    }
}
