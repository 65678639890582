@use 'mixins';

$standardPadding: var(--standardPadding);
$smallPadding: var(--smallPadding);
$iconSize: 1.2em;

.SimpleNewsItem {
    display: block;
    background-color: rgb(var(--lightSectionBack));
    border-bottom: 1px solid rgb(var(--sectionBorder));

    &:last-of-type {
        border-bottom: initial;
    }

    a {
        display: flex;
        flex-direction: row;
        position: relative;
        padding: $standardPadding;
        cursor: pointer;
        text-decoration: none;
        color: rgb(var(--textColor));

        &:hover {
            color: rgb(var(--textColor));
        }
    }

    .thumbnail {
        margin-right: $standardPadding;
    }

    .content {
        display: flex;
        flex-direction: column;
        height: min-content;
        margin-top: auto;
        margin-bottom: auto;
    }

    [class*='TagElement'] {
        display: inline-block;
        height: min-content;
        margin-top: auto;
        margin-right: $smallPadding;
        margin-bottom: auto;
        vertical-align: bottom;
        pointer-events: none;

        :global(.theme-br) & {
            display: block;
            width: min-content;
        }
    }

    .article-title {
        display: inline;
        height: min-content;
        color: rgb(var(--textColor));

        &.limit-3 {
            @include mixins.row-limit(3);
        }

        &.limit-2 {
            @include mixins.row-limit(2);
        }
    }

    :global(.Icon.right-icon) {
        display: none;
    }

    @include mixins.responsive(m, above) {
        .content {
            padding-right: var(--largePadding);
        }

        :global(.Icon.right-icon) {
            display: block;
            position: absolute;
            right: $standardPadding;
            bottom: 50%;
            width: $iconSize;
            height: $iconSize;
            font-size: $iconSize;
            transform: translateY(50%);

            svg {
                path {
                    transform: translateY(-0.1em);
                    fill: rgb(var(--mainColor));

                    :global(.theme-vp) &,
                    :global(.theme-vn) & {
                        fill: rgb(var(--thirdColor));
                    }
                }
            }
        }
    }
}
