@use 'mixins';

$minEmbedHeight: 520px;
$minEmbedHeightMobile: 56.25vw;

%-pro-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 38%;
    height: 100%;
    background: linear-gradient(to right, rgb(0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
}

.Hero {
    position: relative;

    :global(.theme-vn) &,
    :global(.theme-gp) & {
        border-top: 3px solid rgb(var(--mainColor));
    }

    &.has-embed {
        min-height: $minEmbedHeight;
        border-top-width: 0;

        :global(.twitter-tweet),
        :global(.tiktok-embed) {
            margin: 0 auto;
        }

        iframe {
            max-width: 100%;
            height: auto;
            min-height: $minEmbedHeight;
        }
    }

    .edit {
        position: absolute;
        bottom: 0;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: rgb(var(--invertedTextColor));
        height: 3.75em;
        font-weight: 700;
        background-color: rgba(26, 26, 31, 0.4);

        p {
            margin-left: var(--standardPadding);
            z-index: 2;
        }

        button {
            margin-right: var(--standardPadding);
        }
    }

    img {
        width: 100%;
        height: auto;
        max-height: 500px;
        object-fit: cover;

        @include mixins.responsive(m, below) {
            object-position: initial;
        }
    }

    .image-label {
        position: absolute;
        bottom: var(--standardPadding);
        right: var(--standardPadding);
        opacity: 0.8;
        padding: var(--tinyPadding);
        background-color: rgb(var(--textColor));
        border-radius: var(--standardRadius);
        font-size: 0.875em;
        color: rgb(var(--invertedTextColor));
    }

    &.has-edit {
        .image-label {
            right: auto;
            left: var(--standardPadding);
        }
    }

    .inner-tag {
        position: absolute;
        z-index: 1;
        margin-left: var(--standardPadding);
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        :global(.theme-gp) & {
            @extend %heading-small;

            color: rgb(var(--invertedTextColor));
        }
    }

    &.premium {
        display: block;
        position: relative;
        width: 100vw;
        left: 50%;
        transform: translateX(-50%);

        .edit {
            bottom: initial;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to top, rgb(0, 0, 0) 0.01%, rgba(0, 0, 0, 0) 35%);
        }

        .pro-gradient {
            @extend %-pro-gradient;

            @include mixins.responsive(xxl, below) {
                width: 31%;
            }

            @include mixins.responsive(xl, below) {
                width: 25%;
            }

            @include mixins.responsive(l, below) {
                display: none;
            }
        }

        .pro-gradient:last-of-type {
            right: 0;
            left: initial;
            background: linear-gradient(to left, rgb(0, 0, 0) 70%, rgba(0, 0, 0, 0) 100%);
        }

        img {
            display: block;

            width: 1200px;
            max-height: 600px;

            @include mixins.responsive(xxl, above) {
                margin: 0 calc(50% - 600px);
            }

            @include mixins.responsive(xxl, below) {
                width: 988px;
                margin: 0 calc(50% - 494px);
            }

            @include mixins.responsive(l, below) {
                max-height: 400px;
                width: 100%;
                margin: initial;
            }
        }
    }

    @include mixins.responsive(m, below) {
        &.has-embed {
            min-height: $minEmbedHeightMobile;

            iframe {
                min-height: $minEmbedHeightMobile;
            }
        }

        &.premium {
            img {
                width: 100%;
                height: 100%;
                margin: 0;
            }
        }

        .edit {
            font-size: 0.7em;
        }
    }

    @include mixins.responsive(l, below) {
        &.premium {
            margin-left: 0;
            background-color: rgb(var(--darkestBackground));
        }
    }
}
