@use 'mixins';

.NewsHeader {
    display: block;

    h1 {
        @extend %article-heading-large;
    }

    h1,
    h1 + figure {
        margin-top: var(--largePadding);
    }

    // RunningExperiments.ARTICLE_IMAGE A
    > h1 {
        margin-left: var(--standardPadding);
        margin-right: var(--standardPadding);
    }
}

.intro {
    @extend %paragraph-large;

    display: block;
    margin: var(--largePadding) var(--standardPadding);
}

.tag {
    margin: var(--largePadding) 0 calc(var(--standardPadding) * -1) var(--standardPadding);
}

.header-content {
    margin: 0 var(--standardPadding);

    :global(.Icon) {
        font-size: 1.55em;
        color: rgb(var(--lighterTextColor));
    }
}

.meta-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--largePadding);
    padding: var(--standardPadding) 0;
    border-bottom: 1px solid rgb(var(--sectionBorder));
}

.comment-count {
    @extend %caption-medium;

    display: flex;
    align-items: center;
    font-size: 1em;
    height: 2em;
    margin-left: auto;
    color: rgb(var(--lighterTextColor));
    text-decoration: none;

    :global(.Icon) {
        margin-right: 0.125em;
    }
}

@include mixins.responsive(m, below) {
    .share-section {
        display: none;
    }

    .intro {
        font-size: var(--paragraph-medium-font-size);
    }
}
