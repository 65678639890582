@use 'mixins';

.sections {
    display: flex;
    gap: var(--largePadding);
    margin: var(--hugePadding) 0 var(--largePadding);
}

.section {
    &:only-child {
        width: 100%;
    }
}

.section--tag {
    width: 100%;
}

.section-title {
    margin: 0;
    padding: var(--standardPadding);
    border-bottom: 1px solid rgb(var(--sectionBorder));
}

.section-content {
    padding: var(--standardPadding);
}

@include mixins.responsive(m, below) {
    .sections {
        flex-direction: column;
        margin-bottom: var(--hugePadding);
    }

    .section-title {
        font-size: 0.875em;
    }

    .section--share {
        .section-title {
            text-align: left;
        }
    }
}

@include mixins.responsive(m, above) {
    .section--share {
        &:not(:only-child) {
            .section-title {
                text-align: right;
            }
        }
    }
}
