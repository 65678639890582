.AuthorMeta {
    padding: var(--smallPadding) 0;
    border-bottom: 1px solid rgb(var(--sectionBorder));

    a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: rgb(var(--textColor));

        &:hover {
            color: rgb(var(--textColor));

            li:first-of-type {
                text-decoration: underline;
                color: rgb(var(--textColor));
            }
        }
    }

    img {
        width: 2.5em;
        height: 2.5em;
        margin-right: var(--standardPadding);
    }

    li {
        font-size: 0.875em;
        color: rgb(var(--lighterTextColor));
    }

    li:first-of-type {
        padding-bottom: var(--tinyPadding);
        font-size: 1em;
        font-weight: 700;
        color: inherit;
    }

    &.premium {
        border-bottom: 1px solid rgb(var(--secondaryTextColor));
    }
}
