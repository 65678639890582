@use 'mixins';

.SimpleNewsList {
    --cmp-simple-news-list-border-left-color: var(--mainColor);
    --cmp-simple-news-list-box-shadow: 2px 2px rgba(var(--invertedBackground), 0.1);

    :global(.theme-vp) &,
    :global(.theme-vn) & {
        --cmp-simple-news-list-border-left-color: var(--thirdColor);
        --cmp-simple-news-list-box-shadow: none;
    }
}

.SimpleNewsList {
    display: inherit;
    margin: var(--largePadding) var(--standardPadding);
}

.container {
    margin-top: var(--smallPadding);
    margin-bottom: var(--largePadding);
    margin-left: calc(var(--standardPadding) * -1);
    overflow: hidden;
    border-left: 3px solid rgb(var(--cmp-simple-news-list-border-left-color));
    border-radius: var(--standardRadius);
    box-shadow: var(--cmp-simple-news-list-box-shadow);
    line-height: 1.125em;
}

@include mixins.responsive(m, above) {
    .SimpleNewsList {
        margin: var(--largePadding) 0;
    }

    .container {
        margin-top: var(--standardPadding);
    }

    .title {
        transform: translateX(3px);
    }
}
