@use 'mixins';

.DateMeta {
    @extend %caption-medium;

    display: flex;
    align-items: center;
    color: rgb(var(--lighterTextColor));

    .current-date {
        margin-left: var(--tinyPadding);
    }

    &.update .current-date {
        margin-left: 0;
    }

    .date-container {
        display: flex;
        flex-direction: column;
        line-height: normal;
        margin-left: var(--tinyPadding);
    }

    .date {
        display: flex;
        flex-direction: column;
        margin-bottom: var(--standardPadding);
    }

    .date-update {
        font-weight: 700;
        color: rgba(var(--lighterTextColor), 0.5);
        white-space: nowrap;
    }

    :global(.inverted) & {
        .current-date {
            color: rgb(var(--invertedTextColor));
        }

        .date-update {
            color: rgb(var(--lighterTextColor));
        }
    }

    @include mixins.responsive(m, below) {
        .date {
            line-height: 1.5em;
        }
    }
}
